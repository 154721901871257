@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");

body {
  margin: 0;
  font-family: "Inter", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.compx:hover {
  transition: background 0.3s;
  background-color: #fef0c7;
}

.compx_link {
  cursor: pointer;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.compx_anchor {
  color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}
